import { z, ZodFirstPartySchemaTypes, ZodObject } from "zod"

import { Box, Image } from "@chakra-ui/react"
import {
  InvestorHighlight,
  Queries,
  QueriesByUser,
  SpecterProducts,
} from "@prisma/client"
import { ReactNode } from "react"
import {
  COMPANY_HIGHLIGHTS_GROUPS,
  COMPANY_HIGHLIGHTS_PROPS,
  INVESTOR_HIGHLIGHTS_PROPS,
  SIGNAL_DATE_OPTIONS,
} from "~/consts/signals"
import {
  companyAdvancedFiltersSchema,
  CompanySignalFilters,
  companySignalFiltersValidation,
  companySignalQuickFiltersSchema,
} from "./company"
import {
  strategicAdvancedFiltersSchema,
  StrategicSignalFilters,
  strategicSignalFiltersValidation,
  strategicSignalQuickFiltersSchema,
} from "./stratintel"
import {
  talentAdvancedFiltersSchema,
  TalentSignalFilters,
  talentSignalFiltersValidation,
  talentSignalQuickFiltersSchema,
} from "./talent"
import {
  investorsAdvancedFiltersSchema,
  InvestorsDBFilters,
  investorsFiltersValidation,
} from "./investors"
import {
  fundingRoundsAdvancedFiltersSchema,
  FundingRoundsDBFilters,
  fundingRoundsFiltersValidation,
} from "./funding_rounds"
import {
  acquisitionAdvancedFiltersSchema,
  AcquisitionDBFilters,
  acquisitionFiltersValidation,
} from "./acquisition"
import {
  ipoAdvancedFiltersSchema,
  IpoDBFilters,
  ipoFiltersValidation,
} from "./ipo"

export type SignalFilters = {
  company: CompanySignalFilters
  talent: TalentSignalFilters
  stratintel: StrategicSignalFilters
  investors: InvestorsDBFilters
  fundingRounds: FundingRoundsDBFilters
  acquisition: AcquisitionDBFilters
  ipo: IpoDBFilters
}

export type FiltersSchema = ZodObject<{
  [itemKey: string]: FiltersTabSchema
}>

export type FiltersTabSchema = ZodObject<{
  [sectionKey: string]: FiltersSectionSchema
}>

export type FiltersSectionSchema = ZodObject<{
  [itemKey: string]: ZodFirstPartySchemaTypes
}>

export const PRODUCT_FILTERS_SCHEMA = {
  [SpecterProducts.company]: companyAdvancedFiltersSchema,
  [SpecterProducts.talent]: talentAdvancedFiltersSchema,
  [SpecterProducts.stratintel]: strategicAdvancedFiltersSchema,
  [SpecterProducts.investors]: investorsAdvancedFiltersSchema,
  [SpecterProducts.fundingRounds]: fundingRoundsAdvancedFiltersSchema,
  [SpecterProducts.acquisition]: acquisitionAdvancedFiltersSchema,
  [SpecterProducts.ipo]: ipoAdvancedFiltersSchema,
} as const

export const PRODUCT_QUICK_FILTERS_SCHEMA = {
  [SpecterProducts.company]: companySignalQuickFiltersSchema,
  [SpecterProducts.talent]: talentSignalQuickFiltersSchema,
  [SpecterProducts.stratintel]: strategicSignalQuickFiltersSchema,
  [SpecterProducts.investors]: z.object({}),
  [SpecterProducts.fundingRounds]: z.object({}),
  [SpecterProducts.acquisition]: z.object({}),
  [SpecterProducts.ipo]: z.object({}),
} as const

export const PRODUCT_SIGNAL_FILTERS_VALIDATION = {
  [SpecterProducts.company]: companySignalFiltersValidation,
  [SpecterProducts.talent]: talentSignalFiltersValidation,
  [SpecterProducts.stratintel]: strategicSignalFiltersValidation,
  [SpecterProducts.investors]: investorsFiltersValidation,
  [SpecterProducts.fundingRounds]: fundingRoundsFiltersValidation,
  [SpecterProducts.acquisition]: acquisitionFiltersValidation,
  [SpecterProducts.ipo]: ipoFiltersValidation,
} as const

export const PRODUCT_ROUTE_NAMES: Record<string, SpecterProducts> = {
  company: SpecterProducts.company,
  talent: SpecterProducts.talent,
  strategic: SpecterProducts.stratintel,
  investors: SpecterProducts.investors,
  "funding-rounds": SpecterProducts.fundingRounds,
  acquisition: SpecterProducts.acquisition,
  ipo: SpecterProducts.ipo,
} as const

export function mapRouteNameToProduct(
  productRouteName: string
): SpecterProducts {
  return PRODUCT_ROUTE_NAMES[productRouteName]
}

export function mapProductsToRouteName(product: SpecterProducts) {
  switch (product) {
    case SpecterProducts.stratintel:
      return "strategic"
    case SpecterProducts.fundingRounds:
      return "funding-rounds"
    default:
      return product
  }
}

//
// * SUGGESTED SEARCHES
//

export type SuggestedSearchSchema = {
  query: Queries["query"]
  sort?: QueriesByUser["sort"]
  searchId?: number
}

export type SuggestedSearchMenuItemSchema = SuggestedSearchSchema & {
  label: ReactNode
}

export const PRODUCT_SUGGESTED_SEARCHES: Record<
  SpecterProducts,
  Record<
    string,
    | SuggestedSearchMenuItemSchema[]
    | SuggestedSearchSchema
    | (() => Promise<SuggestedSearchMenuItemSchema[]>)
  >
> = {
  [SpecterProducts.company]: {
    "Companies by Stage 🌳": [
      {
        label: "Bootstrapped 🐥",
        query: {
          growthStage: ["no_funding"],
        },
        searchId: 2168,
      },
      {
        label: "Pre-seed / Seed 🌱",
        query: {
          growthStage: ["seed"],
        },
        searchId: 2169,
      },
      {
        label: "Early Stage 🪴",
        query: {
          growthStage: ["early"],
        },
        searchId: 2170,
      },
      {
        label: "Growth & Late Stage 🌳",
        query: {
          growthStage: ["growing", "late"],
        },
        searchId: 2246,
      },
    ],
    "Region 🌍": [
      {
        label: "North America",
        query: {
          HQRegion: ["Canada", "United States"],
        },
      },
      {
        label: "Europe + Israel",
        query: {
          HQRegion: ["Europe"],
        },
      },
      {
        label: "European Union",
        query: {
          HQLocation: [
            "289356276058554368:289637751035265023", // "Austria"
            "289919226011975680:290200700988686335", // "Belgium"
            "290482175965396992:290763650942107647", // "Bulgaria"
            "290763650942107648:291045125918818303", // "Croatia"
            "291045125918818304:291326600895528959", // "Cyprus"
            "291326600895528960:291608075872239615", // "Czechia"
            "291608075872239616:291889550848950271", // "Denmark"
            "291889550848950272:292171025825660927", // "Estonia"
            "292452500802371584:292733975779082239", // "Finland"
            "292733975779082240:293015450755792895", // "France"
            "293015450755792896:293296925732503551", // "Germany"
            "293578400709214208:293859875685924863", // "Greece"
            "294422825639346176:294704300616056831", // "Hungary"
            "294985775592767488:295267250569478143", // "Ireland"
            "295830200522899456:296111675499610111", // "Italy"
            "296674625453031424:296956100429742079", // "Latvia"
            "297237575406452736:297519050383163391", // "Lithuania"
            "297519050383163392:297800525359874047", // "Luxembourg"
            "298082000336584704:298363475313295359", // "Malta"
            "302867074940665856:303148549917376511", // "Netherlands"
            "299770850196848640:300052325173559295", // "Poland"
            "300052325173559296:300333800150269951", // "Portugal"
            "300333800150269952:300615275126980607", // "Romania"
            "301178225080401920:301459700057112575", // "Slovakia"
            "301459700057112576:301741175033823231", // "Slovenia"
            "301741175033823232:302022650010533887", // "Spain"
            "302304124987244544:302585599963955199", // "Sweden"
          ],
        },
      },
      {
        label: "CEE",
        query: {
          HQLocation: [
            "288793326105133056:289074801081843711", // "Albania"
            "289637751035265024:289919226011975679", // "Belarus"
            "290200700988686336:290482175965396991", // "Bosnia Herzegovina"
            "290482175965396992:290763650942107647", // "Bulgaria"
            "290763650942107648:291045125918818303", // "Croatia"
            "291326600895528960:291608075872239615", // "Czech"
            "291889550848950272:292171025825660927", // "Estonia"
            "294422825639346176:294704300616056831", // "Hungary"
            "296393150476320768:296674625453031423", // "Kosovo"
            "296674625453031424:296956100429742079", // "Latvia"
            "297237575406452736:297519050383163391", // "Lithuania"
            "297800525359874048:298082000336584703", // "Macedonia"
            "298644950290006016:298926425266716671", // "Moldova"
            "299207900243427328:299489375220137983", // "Montenegro"
            "299770850196848640:300052325173559295", // "Poland"
            "300333800150269952:300615275126980607", // "Romania"
            "300896750103691264:301178225080401919", // "Serbia"
            "301178225080401920:301459700057112575", // "Slovakia"
            "301459700057112576:301741175033823231", // "Slovenia"
            "303148549917376512:303430024894087167", // "Ukraine"
            "289356276058554368:289637751035265023", // "Austria"
          ],
        },
      },
      {
        label: "Nordics",
        query: {
          HQLocation: [
            "302304124987244544:302585599963955199", // "Sweden"
            "299489375220137984:299770850196848639", // "Norway"
            "291608075872239616:291889550848950271", // "Denmark"
            "297237575406452736:297519050383163391", // "Lithuania"
            "296674625453031424:296956100429742079", // "Latvia"
            "291889550848950272:292171025825660927", // "Estonia"
            "292452500802371584:292733975779082239", // "Finland"
          ],
        },
      },
      {
        label: "Oceania",
        query: {
          HQRegion: ["Oceania"],
        },
      },
      {
        label: "Emerging Markets",
        query: {
          HQRegion: ["Africa", "Asia", "LATAM", "MENA", "Russia"],
        },
      },
    ],
    "Highlights ✨": Object.keys(COMPANY_HIGHLIGHTS_PROPS).map((option) => {
      const { group, label } = COMPANY_HIGHLIGHTS_PROPS[option]
      const { colorScheme } = COMPANY_HIGHLIGHTS_GROUPS[group]
      return {
        label: (
          <>
            <Box
              boxSize={2}
              bgColor={`${colorScheme}.400`}
              borderRadius="full"
              mr={2}
            />
            {label}
          </>
        ),
        query: {
          Highlights: ["OR", [option]],
        },
      }
    }),
    "Awards 🏆": async () => {
      const options: { value: string; logoUrl: string }[] = await fetch(
        `/api/input-options/company/awards`
      ).then((result) => result.json())

      return options.map((option) => ({
        label: (
          <>
            <Image src={option.logoUrl} boxSize="12px" mr={1.5} />
            {option.value}
          </>
        ),
        query: {
          Awards: ["OR", [option.value]],
        },
      }))
    },
  },
  [SpecterProducts.talent]: {
    "New Founders 🌍": [
      {
        label: "New Founders - North America",
        query: {
          SignalType: ["New Company"],
          Region: ["Canada", "United States"],
        },
      },
      {
        label: "New Founders - Europe + Israel",
        query: {
          SignalType: ["New Company"],
          Region: ["Europe"],
        },
      },
      {
        label: "New Founders - Nordics",
        query: {
          SignalType: ["New Company"],
          Location: [
            "302304124987244544:302585599963955199", // "Sweden"
            "299489375220137984:299770850196848639", // "Norway"
            "291608075872239616:291889550848950271", // "Denmark"
            "297237575406452736:297519050383163391", // "Lithuania"
            "296674625453031424:296956100429742079", // "Latvia"
            "291889550848950272:292171025825660927", // "Estonia"
            "292452500802371584:292733975779082239", // "Finland"
          ],
        },
      },
      {
        label: "New Founders - CEE",
        query: {
          Location: [
            "288793326105133056:289074801081843711", // "Albania"
            "289637751035265024:289919226011975679", // "Belarus"
            "290200700988686336:290482175965396991", // "Bosnia Herzegovina"
            "290482175965396992:290763650942107647", // "Bulgaria"
            "290763650942107648:291045125918818303", // "Croatia"
            "291326600895528960:291608075872239615", // "Czech"
            "291889550848950272:292171025825660927", // "Estonia"
            "294422825639346176:294704300616056831", // "Hungary"
            "296393150476320768:296674625453031423", // "Kosovo"
            "296674625453031424:296956100429742079", // "Latvia"
            "297237575406452736:297519050383163391", // "Lithuania"
            "297800525359874048:298082000336584703", // "Macedonia"
            "298644950290006016:298926425266716671", // "Moldova"
            "299207900243427328:299489375220137983", // "Montenegro"
            "299770850196848640:300052325173559295", // "Poland"
            "300333800150269952:300615275126980607", // "Romania"
            "300896750103691264:301178225080401919", // "Serbia"
            "301178225080401920:301459700057112575", // "Slovakia"
            "301459700057112576:301741175033823231", // "Slovenia"
            "303148549917376512:303430024894087167", // "Ukraine"
            "289356276058554368:289637751035265023", // "Austria"
          ],
          SignalType: ["New Company"],
        },
      },
      {
        label: "New Founders - Oceania",
        query: {
          Region: ["Oceania"],
          SignalType: ["New Company"],
        },
      },
      {
        label: "New Founders - Emerging Markets",
        query: {
          SignalType: ["New Company"],
          Region: ["Africa", "Asia", "LATAM", "MENA", "Russia"],
        },
      },
    ],
    "New Stealth Startups 👻": [
      {
        label: "New Founders in Stealth",
        query: {
          SignalStatus: ["Stealth"],
          SignalType: ["New Company"],
          TalentLatestSignal: "Yes",
        },
      },
      {
        label: "New Roles in Stealth",
        query: {
          SignalStatus: ["Stealth"],
          SignalType: ["New Role"],
          TalentLatestSignal: "Yes",
        },
      },
      {
        label: "Founders coming Out of Stealth",
        query: {
          SignalStatus: ["Out of Stealth"],
          TalentLatestSignal: "Yes",
        },
      },
    ],
    "Spinout Founders 🧬": [
      {
        label: "Founders ex-Unicorns 🦄",
        query: {
          SignalDate: SIGNAL_DATE_OPTIONS.THIS_WEEK,
          SignalType: ["New Company"],
          PastCompanyWebsite: [
            "equals",
            [
              "bytedance.com",
              "spacex.com",
              "stripe.com",
              "canva.com",
              "revolut.com",
              "epicgames.com",
              "databricks.com",
              "fanatics.com",
              "openai.com",
              "chime.com",
              "xiaohongshu.com",
              "jtexpress.sg",
              "miro.com",
              "yuanfudao.com",
              "dji.com",
              "discord.com",
              "gopuff.com",
              "yuanqisenlin.com",
              "ripple.com",
              "blockchain.com",
              "plaid.com",
              "opensea.io",
              "celonis.com",
              "grammarly.com",
              "devoted.com",
              "faire.com",
              "brex.com",
              "instacart.com",
              "bitmain.com",
              "biosplice.com",
              "goodleap.com",
              "xsyxsc.com",
              "deel.com",
              "airtable.com",
              "byjus.com",
              "zongmutech.com",
              "rippling.com",
              "globalswitch.com",
              "checkout.com",
              "bolt.com",
              "alchemy.com",
              "huolala.cn",
              "wiz.io",
              "gusto.com",
              "guazi.com",
              "reddit.com",
              "talkdesk.com",
              "notion.so",
              "thrasio.com",
              "dcg.co",
              "kucoin.com",
              "servicetitan.com",
              "heytea.com",
              "n26.com",
              "klaviyo.com",
              "navan.com",
              "northvolt.com",
              "tanium.com",
              "nianticlabs.com",
              "oyorooms.com",
              "getir.uk",
              "rapyd.net",
              "kavak.com",
              "nuro.ai",
              "chainalysis.com",
              "pony.ai",
              "personio.com",
              "sumup.co.uk",
              "anduril.com",
              "bolt.com",
              "lacework.com",
              "tipalti.com",
              "tempus.com",
              "ramp.com",
              "swiggy.com",
              "dream11.com",
              "fireblocks.com",
              "flexport.com",
              "falconx.io",
              "starkware.co",
              "carislifesciences.com",
              "hopin.com",
              "dapperlabs.com",
              "netskope.com",
              "razorpay.com",
              "automattic.com",
              "olacabs.com",
              "carta.com",
              "snyk.io",
              "scale.com",
              "gong.io",
              "gemini.com",
              "toss.im",
              "guahao.com",
              "ro.co",
              "consensys.net",
              "automationanywhere.com",
              "1password.com",
              "klarna.com",
              "ziroom.com",
              "svolt.cn",
              "nseindia.com",
              "mollie.com",
              "doctolib.fr",
              "cred.club",
              "datarobot.com",
              "upgrade.com",
              "hingehealth.com",
              "blackunicornfactorycalifornia.com",
              "benchling.com",
              "global.royole.com",
              "better.com",
              "icapitalnetwork.com",
              "attentivemobile.com",
              "lyrahealth.com",
              "juran.com.cn",
              "bj.lianjia.com",
              "vice.com",
              "cityblock.com",
              "workato.com",
              "backmarket.com",
              "relexsolutions.com",
              "boringcompany.com",
              "postman.com",
              "fivetran.com",
              "contentsquare.com",
              "airwallex.com",
              "mambu.com",
              "traderepublic.com",
              "rappi.com.mx",
              "collibra.com",
              "6sense.com",
              "onetrust.com",
              "quintoandar.com.br",
              "c6bank.com.br",
              "miflink.com",
              "hello-inc.com",
              "horizon.cc",
              "howdengroupholdings.com",
              "sambanova.ai",
              "zepzpay.com",
              "onci.oaknorth.com",
              "moonactive.com",
              "ofbusiness.com",
              "cockroachlabs.com",
              "pinelabs.com",
              "qonto.com",
              "olaelectric.in",
              "icertis.com",
              "hopper.com",
              "verse.in",
              "sharechat.com",
              "coalitioninc.com",
              "grafana.com",
              "meesho.com",
              "cerebral.com",
              "creditas.com",
              "reifyhealth.com",
              "en.chiponeic.com",
              "betterup.com",
              "pleo.io",
              "sonarsource.com",
              "checkr.com",
              "color.com",
              "lendable.co.uk",
              "ubtrobot.com",
              "vinted.com",
              "vipkid.com",
              "socure.com",
              "monzo.com",
              "wefox.com",
              "lenskart.com",
              "anthropic.com",
              "outreach.io",
              "weride.ai",
              "guildeducation.com",
              "arcticwolf.com",
              "outsystems.com",
              "sorare.com",
              "miaoshou.com",
              "flyzipline.com",
              "relativityspace.com",
              "thoughtspot.com",
              "globalization-partners.com",
              "getdbt.com",
              "ssense.com",
              "bitpanda.com",
              "dataminr.com",
              "sayweee.com",
              "skydance.com",
              "houzz.com",
              "yellomobile.com",
              "megvii.com",
              "greensill.com",
              "impossiblefoods.com",
              "radpartners.com",
              "nextinsurance.com",
              "pointclickcare.com",
              "zapier.com",
              "joinclubhouse.com",
              "browserstack.com",
              "oliveai.com",
              "rubrik.com",
              "meliopayments.com",
              "vuoriclothing.com",
              "clickup.com",
              "cerebras.net",
              "fbn.com",
              "branch.io",
              "aurorasolar.com",
              "webflow.com",
              "yuga.com",
              "godigit.com",
              "abnormalsecurity.com",
              "hozonauto.com",
              "medlinker.com",
              "intarcia.com",
              "stockx.com",
              "messagebird.com",
              "convoy.com",
              "articulate.com",
              "dutchie.com",
              "cohesity.com",
              "dataiku.com",
              "vastdata.com",
              "goat.com",
              "noom.com",
              "redwoodmaterials.com",
              "papayaglobal.com",
              "abogenbio.com",
              "harness.io",
              "whatnot.com",
              "autograph.io",
              "relativity.com",
              "whoop.com",
              "appliedintuition.com",
              "coursehero.com",
              "spoton.com",
              "ottobock.com",
              "indigoag.com",
              "etoro.com",
              "hyalroute.com",
              "recroom.com",
              "commure.com",
              "mirakl.com",
              "tekion.com",
              "celsius.network",
              "gostudent.org",
              "highspot.com",
              "joinhandshake.com",
              "chargebee.com",
              "flocksafety.com",
              "helionenergy.com",
              "acronis.com",
              "ridewithvia.com",
              "unacademy.com",
              "odoo.com",
              "moonpay.com",
              "upstox.com",
              "improbable.io",
              "youxiamotors.com",
              "ownbackup.com",
              "starburst.io",
              "silanano.com",
              "komodohealth.com",
              "kurly.com",
              "cars24.com",
              "blockdaemon.com",
              "dadicinema.com",
              "aiven.io",
              "asteralabs.com",
              "thumbtack.com",
              "cedar.com",
              "eruditus.com",
              "blockstream.com",
              "innovaccer.com",
              "ironcladapp.com",
              "eikontx.com",
              "retool.com",
              "verkada.com",
              "applyboard.com",
              "psiquantum.com",
              "udaan.com",
              "highradius.com",
              "nuvemshop.com",
              "layerzero.network",
              "yixia.com",
              "traveloka.com",
              "souche.com",
              "bglgroup.co.uk",
              "circle.com",
              "zybang.com",
              "flixbus.com",
              "wildlifestudios.com",
              "forter.com",
              "calendly.com",
              "blockfi.com",
              "activecampaign.com",
              "cmrsurgical.com",
              "lucid.co",
              "ageoflearning.com",
              "carbonhealth.com",
              "contentful.com",
              "launchdarkly.com",
              "seismic.com",
              "skymavis.com",
              "outschool.com",
              "tradingview.com",
              "groww.in",
              "podium.com",
              "anchorage.com",
              "inxeption.com",
              "lattice.com",
              "flutterwave.com",
              "ambergroup.io",
              "crossriver.com",
              "remote.com",
              "sentry.io",
              "kkguan.com",
              "kraken.com",
              "workrise.com",
              "loft.com.br",
              "gokinsolar.com",
              "bharatpe.com",
              "drivewealth.com",
              "alan.com",
              "gomotive.com",
              "meicai.cn",
              "nutrabolt.com",
              "graphcore.ai",
              "illumio.com",
              "masterclass.com",
              "dreamgames.com",
              "transmitsecurity.com",
              "tradeshift.com",
              "nextiva.com",
              "tridge.com",
              "o9solutions.com",
              "thoughtmachine.net",
              "ethoslife.com",
              "starlingbank.com",
              "zetwerk.com",
              "backbase.com",
              "about.sourcegraph.com",
              "jumpcloud.com",
              "project44.com",
              "freenome.com",
              "manomano.fr",
              "pendo.io",
              "plume.com",
              "moglix.com",
              "axonius.com",
              "unico.io",
              "clari.com",
              "ju.st",
              "ouraring.com",
              "cribl.io",
              "jettiresources.com",
              "vistajet.com",
              "byton.com",
              "sideinc.com",
              "springhealth.com",
              "infra.market",
              "varomoney.com",
              "catonetworks.com",
              "aura.com",
              "vercel.com",
              "sysdig.com",
              "uniphore.com",
              "somatus.com",
              "immutable.com",
              "a24films.com",
              "sifive.com",
              "games24x7.com",
              "fetch.com",
              "codapayments.com",
              "drivenets.com",
              "hibob.com",
              "uala.com.ar",
              "mpl.live",
              "Cgtz.com",
              "starcharge.com",
              "equipmentshare.com",
              "carbon3d.com",
              "rokt.com",
              "policybazaar.com",
              "paxos.com",
              "exabeam.com",
              "bitsight.com",
              "trumid.com",
              "ablspacesystems.com",
              "honeybook.com",
              "beta.team",
              "manypets.com",
              "jobandtalent.com",
              "wemakeprice.com",
              "birentech.com",
              "voodoo.io",
              "shield.ai",
              "uptake.com",
              "Greenlight.com",
              "boweryfarming.com",
              "momo.vn",
              "algolia.com",
              "pattern.com",
              "upgrad.com",
              "coreweave.com",
              "newfront.com",
              "nucom.group",
              "skydio.com",
              "global.musinsa.com",
              "current.com",
              "bitso.com",
              "gympass.com",
              "dialpad.com",
              "g7.com.cn",
              "bloomreach.com",
              "madewithmotif.com",
              "huaqin.com",
              "accelins.com",
              "yitutech.com",
              "addepar.com",
              "qualia.com",
              "cloudwalk.com",
              "coindcx.com",
              "firstcry.com",
              "Eightfold.ai",
              "urbancompany.com",
              "rechargepayments.com",
              "moveworks.com",
              "medable.com",
              "gocardless.com",
              "aledade.com",
              "forto.com",
              "tryjeeves.com",
              "payfit.com",
              "vivawallet.com",
              "xinchao.com",
              "neo4j.com",
              "babel.finance",
              "bezerocarbon.com",
              "trendygp.com",
              "grubmarket.com",
              "cohere.com",
              "go1.com",
              "clearstreet.io",
              "drata.com",
              "mystenlabs.com",
              "avant.com",
              "tubatu.com",
              "blablacar.com",
              "huimin.cn",
              "quora.com",
              "preferred.jp",
              "formlabs.com",
              "4paradigm.com",
              "calm.com",
              "kaseya.com",
              "mafengwo.cn",
              "druva.com",
              "kujiale.com",
              "appsflyer.com",
              "keephq.dev",
              "redis.com",
              "xingyungroup.com",
              "unqork.com",
              "virtahealth.com",
              "isnetworld.com",
              "earnix.com",
              "zzgqsh.com",
              "clear.co",
              "hive.com",
              "kry.se",
              "kajabi.com",
              "beisen.com",
              "pipe.com",
              "iterable.com",
              "bunq.com",
              "clip.mx",
              "loggi.com",
              "mural.co",
              "ocsial.com",
              "xtalpi.com",
              "divvyhomes.com",
              "apeel.com",
              "opayweb.com",
              "aviatrix.com",
              "smartnews.com",
              "misfitsmarket.com",
              "bst.ai",
              "advancegroup.com",
              "moderntreasury.com",
              "magicleap.com",
              "tier.app",
              "clickhouse.com",
              "everlaw.com",
              "zilch.com",
              "verbit.ai",
              "netlify.com",
              "swordhealth.com",
              "shopltk.com",
              "traxretail.com",
              "exotec.com",
              "dremio.com",
              "akulaku.com",
              "cloudinary.com",
              "lunar.app",
              "salsify.com",
              "certik.com",
              "roxmotor.com",
              "huggingface.co",
              "devo.com",
              "shiftkey.com",
              "rimac-automobili.com",
              "geekplus.com",
              "21.co",
              "solugen.com",
              "chippercash.com",
              "ankorstore.com",
              "feverup.com",
              "roofstock.com",
              "thatgamecompany.com",
              "zigbang.com",
              "invisionapp.com",
              "edaili.com",
              "mx.com",
              "coinswitch.co",
              "acorns.com",
              "concertai.com",
              "materialbank.com",
              "volocopter.com",
              "enovatemotors.com",
              "cfgi.com",
              "megazone.com",
              "zocdoc.com",
              "scroll.com",
              "diamondfoundry.com",
              "quantexa.com",
              "fullstory.com",
              "Lightricks.com",
              "opentrons.com",
              "kindbody.com",
              "sliceit.com",
              "alpha-sense.com",
              "orca.security",
              "cambrianbio.com",
              "ai-ways.com",
              "trulioo.com",
              "spinny.com",
              "crusoeenergy.com",
              "apusapps.com",
              "productboard.com",
              "forte.io",
              "harrys.com",
              "pax.com",
              "dispatchhealth.com",
              "pax8.com",
              "multiverse.io",
              "front.com",
              "redesignhealth.com",
              "circleci.com",
              "wave.com",
              "vestiairecollective.com",
              "dragos.com",
              "reltio.com",
              "h2o.ai",
              "zenbusiness.com",
              "carsome.my",
              "spotter.com",
              "dealshare.in",
              "homelight.com",
              "gousto.co.uk",
              "alation.com",
              "tesseratherapeutics.com",
              "uniswap.org",
              "kredivocorp.com",
              "optimism.io",
              "incrediblehealth.com",
              "solarisbank.com",
              "glossier.com",
              "jusfoun.com",
              "transcarent.co",
              "zbj.com",
              "uniteus.com",
              "datastax.com",
              "infinidat.com",
              "afiniti.com",
              "caocaokeji.cn",
              "clio.com",
              "safetyculture.com",
              "montecarlodata.com",
              "cresta.com",
              "asapp.com",
              "smarthr.co.jp",
              "mercury.com",
              "truepill.com",
              "ezcater.com",
              "wayflyer.com",
              "capitolis.com",
              "nordsecurity.com",
              "vanta.com",
              "magiceden.io",
              "pave.com",
              "astranis.com",
              "promasidor.com",
              "yinhehangtian.cn",
              "icon.foundation",
              "fresh-scm.cn",
              "alloy.co",
              "baiwang.com",
              "iluvatar.com",
              "wenheyou.com",
              "kitopi.com",
              "loom.com",
              "ximalaya.com",
              "ncarzone.com",
              "cognite.com",
              "runwayml.com",
              "openweb.com",
              "bolttech.io",
              "firstmode.com",
              "biltrewards.com",
              "mu-sigma.com",
              "kushkipagos.com",
              "jasper.ai",
              "ornatx.com",
              "tujia.com",
              "52mf.com.cn",
              "gett.com",
              "dtdream.com",
              "xiaozhu.com",
              "yijiupi.com",
              "cmtelematics.com",
              "collectivehealth.com",
              "strava.com",
              "zenoti.com",
              "khealth.ai",
              "uplight.com",
              "id.me",
              "snapdocs.com",
              "nextsilicon.com",
              "yunquna.com",
              "impact.com",
              "smartrecruiters.com",
              "thenotcompany.com",
              "cultureamp.com",
              "taxbit.com",
              "pacaso.com",
              "1047games.com",
              "matillion.com",
              "withpersona.com",
              "ascendcorp.com",
              "andela.com",
              "built.com",
              "coinlist.co",
              "lusha.com",
              "cure.fit",
              "olist.com",
              "paradox.ai",
              "airbyte.io",
              "store-dot.com",
              "oddity.ai",
              "spendesk.com",
              "veriff.com",
              "athelas.com",
              "elastic.run",
              "veho-technologies.com",
              "temporal.io",
              "meetfabric.com",
              "zeta.tech",
              "boba.com",
              "upside.app",
              "mashgin.com",
              "dock.tech",
              "prometheus.io",
              "5ire.com",
              "ziphq.com",
              "moloco.com",
              "inari.com",
              "ypshengxian.com",
              "bordrin.com",
              "arcadia.com",
              "ledger.com",
              "vts.com",
              "shukun.net",
              "huasun.com",
              "coocaa.com",
              "jumapeisong.com",
              "ouyeel.com",
              "gymshark.com",
              "m1.com",
              "aleo.org",
              "einride.tech",
              "justworks.com",
              "valgenmed.com",
              "sondermind.com",
              "amagi.com",
              "joinpapa.com",
              "xpressbees.com",
              "figment.io",
              "envoy.com",
              "deliverect.com",
              "sparkcognition.com",
              "liquidity.com",
              "firebolt.io",
              "koudai.com",
              "symphony.com",
              "yidianzixun.com",
              "cabify.com",
              "hivebox.com",
              "awaytravel.com",
              "konghq.com",
              "epidemicsound.com",
              "yotpo.com",
              "coda.io",
              "stash.com",
              "radpowerbikes.com",
              "clarifyhealth.com",
              "bucketplace.com",
              "paddle.com",
              "onecard.io",
              "boat-lifestyle.com",
              "phenompeople.com",
              "gupshup.io",
              "degreed.com",
              "scalable.capital",
              "videoamp.com",
              "panther-labs.com",
              "salt.security",
              "tripledotstudios.com",
              "nimblerx.com",
              "pristyncare.com",
              "vente-privee.com",
              "banconeon.com",
              "extend.com",
              "generatebiomedicines.com",
              "movableink.com",
              "patreon.com",
              "deepblueai.com",
              "klook.com",
              "ysbang.cn",
              "epirus.com",
              "signifyd.com",
              "jaguarmicro.com",
              "motorway.co.uk",
              "ridi.kr",
              "flipboard.com",
              "gpclub.vn",
              "tongdun.cn",
              "athleticgreens.com",
              "unisound.com",
              "alzheon.com",
              "mastercontrol.com",
              "heartflow.com",
              "shiprocket.in",
              "thebrandtechgroup.com",
              "insig.com",
              "everlyhealth.com",
              "wearemanner.com",
              "talkingdata.com",
              "distrokid.com",
              "konfio.mx",
              "betterment.com",
              "flockfreight.com",
              "yugabyte.com",
              "lukka.tech",
              "travelperk.com",
              "itrustcapital.com",
              "domestika.org",
              "cointracker.io",
              "loadsmart.com",
              "tungee.com",
              "yubi.io",
              "linktr.ee",
              "island.io",
              "clipboardhealth.com",
              "biofourmis.com",
              "stord.com",
              "optibus.com",
              "equashield.com",
              "jokr.com",
              "cava.com",
              "intercom.com",
              "ovoenergy.com",
              "huisuanzhang.com",
              "wtoip.com",
              "peoplecert.org",
              "gbtrnd.com",
              "jmgo.com",
              "bigid.com",
              "kuaikanmanhua.com",
              "marshmallow.com",
              "joinhonor.com",
              "mythicalgames.com",
              "incode.com",
              "tackle.io",
              "flipdish.com",
              "route.com",
              "captivateiq.com",
              "talos.com",
              "airslate.com",
              "classdojo.com",
              "brewdog.com",
              "enflame-tech.com",
              "shulanhealth.com",
              "seatgeek.com",
              "eqrx.com",
              "nexii.com",
              "indrive.com",
              "spiber.jp",
              "useinsider.com",
              "kallyope.com",
              "replit.com",
              "ournextenergy.com",
              "yiguo.com",
              "workhuman.com",
              "qumulo.com",
              "tealium.com",
              "public.com",
              "pipacoding.com",
              "pilot.com",
              "aibee.com",
              "vectra.ai",
              "ada.cx",
              "smartmore.com",
              "rohlik.group",
              "floqast.com",
              "mindtickle.com",
              "dailypay.com",
              "offchainlabs.com",
              "copado.com",
              "gem.co",
              "cardekho.com",
              "myglamm.com",
              "agentsync.io",
              "merama.com",
              "seekout.com",
              "bigpanda.io",
              "phantom.app",
              "livspace.com",
              "helium.com",
              "choco.com",
              "viz.ai",
              "unit.co",
              "storicard.com",
              "spectrummed.com",
              "swiftly.com",
              "colossal.com",
              "mediheal.com",
              "mininglamp.com",
              "igetget.com",
              "yimidida.com",
              "modernhealth.com",
              "irl.com",
              "tuhu.cn",
              "younited-credit.com",
              "source.co",
              "cloudbees.com",
              "lifemiles.com",
              "venafi.com",
              "guideline.com",
              "rebelliondefense.com",
              "elemy.com",
              "happymoney.com",
              "dana.id",
              "teld.cn",
              "at-bay.com",
              "visbymedical.com",
              "invoca.com",
              "wasabi.com",
              "tango.me",
              "appdirect.com",
              "juanpi.com",
              "ovhcloud.com",
              "getyourguide.com",
              "ivalua.com",
              "sisense.com",
              "pharmapacks.com",
              "sennder.com",
              "nexthink.com",
              "zego.com",
              "rightwayhealthcare.com",
              "sunbit.com",
              "qingting.fm",
              "g2.com",
              "caribou.com",
              "nowports.com",
              "people.ai",
              "carousell.com",
              "apna.co",
              "karat.com",
              "enpal.de",
              "acko.com",
              "daily-harvest.com",
              "thebankoflondon.com",
              "fundbox.com",
              "turing.com",
              "globalbees.com",
              "leadschool.in",
              "esentire.com",
              "beyondidentity.com",
              "staffbase.com",
              "chief.com",
              "intelycare.com",
              "teleport.io",
              "imply.io",
              "material.security",
              "pw.live",
              "recoverfiber.com",
              "purplle.com",
              "caisgroup.com",
              "gaussianrobotics.com",
              "nxin.com",
              "uisee.com",
              "56pingtai.net",
              "drinkcirkul.com",
              "radiuspaymentsolutions.com",
              "rivigo.com",
              "mamaearth.in",
              "mobilecoin.com",
              "0x.org",
              "jiuxian.com",
              "instabase.com",
              "sendbird.com",
              "density.exchange",
              "mixpanel.com",
              "ourbranch.com",
              "matrixport.com",
              "orcam.com",
              "bcmaterial.com",
              "ample.com",
              "blackbuck.com",
              "leapmotor.com",
              "wheel.com",
              "flexe.com",
              "hailo.ai",
              "avenue.one",
              "deepl.com",
              "percent.com",
              "palmetto.com",
              "mnt-halan.com",
              "prove.com",
              "character.ai",
              "atmosphere.tv",
              "adept.ai",
              "beamery.com",
              "flow.io",
              "xanadu.ai",
              "lmwn.com",
              "celestia.org",
              "stability.ai",
              "vesttoo.com",
              "satispay.com",
              "paystand.com",
              "turntide.com",
              "ecovadis.com",
              "transfermate.com",
              "lookout.com",
              "perimeter81.com",
              "snapdeal.com",
              "techstylefashiongroup.com",
              "zebec.io",
              "inmobi.com",
              "wifi.com",
              "redventures.com",
              "beibei.com",
              "lamabang.com",
              "jimu.com",
              "fxiaoke.com",
              "mia.com",
              "daojia.com",
              "womai.com",
              "hujiang.com",
              "itutorgroup.com",
              "mindmaze.com",
              "icarbonx.com",
              "kendrascott.com",
              "mobvoi.com",
              "zhuanzhuan.com",
              "modmed.com",
              "zhaogang.com",
              "dianrong.com",
              "cellc.co.za",
              "yhglobal.com",
              "revolutionprecrafted.com",
              "welab.co",
              "maimai.cn",
              "dxy.cn",
              "hmdglobal.com",
              "huike.com",
              "chinac.com",
              "linkdoc.com",
              "mediamath.com",
              "movile.com",
              "patmcgrath.com",
              "wacai.com",
              "ishansong.com",
              "ebanma.com",
              "tresata.com",
              "momenta.cn",
              "hosjoy.com",
              "omio.com",
              "tslsmart.com",
              "bitfury.com",
              "reeftechnology.com",
              "globality.com",
              "ynsect.com",
              "intellif.com",
              "poizon.com",
              "sitechdev.com",
              "knowbox.cn",
              "yanolja.com",
              "meero.com",
              "ibotta.com",
              "c2fo.com",
              "numbrs.com",
              "ebanx.com",
              "alto.com",
              "hotmart.com",
              "dubizzlegroup.com",
              "quizlet.com",
              "orcabio.com",
              "infobip.com",
              "zwift.com",
              "play.co",
              "boomsupersonic.com",
              "quantummetric.com",
              "newsbreak.com",
              "madeiramadeira.com.br",
              "ppro.com",
              "splashtop.com",
              "flashexpress.com",
              "axiomspace.com",
              "locusrobotics.com",
              "standard.ai",
              "webull.com",
              "newsela.com",
              "aquasec.com",
              "patsnap.com",
              "evidation.com",
              "sidecarhealth.com",
              "feedzai.com",
              "yxt.com",
              "cameo.com",
              "hyperchain.cn",
              "thezebra.com",
              "clearcover.com",
              "groq.com",
              "fiture.com",
              "injective.com",
              "sift.com",
              "teya.com",
              "capsule.com",
              "mux.com",
              "xforceplus.com",
              "augustinusbader.com",
              "shift-technology.com",
              "tenstorrent.com",
              "zihaiguo.com",
              "axtria.com",
              "amount.com",
              "vise.com",
              "printful.com",
              "goshippo.com",
              "thirtymadison.com",
              "letsgetchecked.com",
              "ecoflow.sg",
              "carro.co",
              "tractable.ai",
              "xiaoe-tech.com",
              "bringg.com",
              "claroty.com",
              "hellosimply.com",
              "aircall.io",
              "worldcoin.org",
              "smartasset.com",
              "morningconsult.com",
              "visier.com",
              "shipbob.com",
              "amperity.com",
              "joinpantheon.io",
              "xiaoice.com",
              "dmedglobal.com",
              "carsongroup.com",
              "interos.ai",
              "nium.com",
              "humaninterest.com",
              "bluecore.com",
              "freshbooks.com",
              "snorkel.ai",
              "gelato.com",
              "mavenclinic.com",
              "picsart.com",
              "1kmxc.com",
              "shopcider.com",
              "berlinbrandsgroup.com",
              "agilerobots.com",
              "mammothdiagnostics.com",
              "orchard.com",
              "xendit.co",
              "truelayer.com",
              "withassembly.com",
              "pandadoc.com",
              "keenonrobot.com",
              "haydonkerkpittman.com",
              "ninjavan.co",
              "vedantu.com",
              "trialspark.com",
              "ajaib.co.id",
              "licious.in",
              "masterworks.com",
              "chronosphere.io",
              "solo.io",
              "savagex.com",
              "swile.co",
              "mobikwik.com",
              "wandb.ai",
              "zopa.com",
              "dental-monitoring.com",
              "cargox.com.br",
              "getfabric.com",
              "augury.com",
              "mokahr.com",
              "tezign.com",
              "vagaro.com",
              "razor-group.com",
              "igaworks.com",
              "contrastsecurity.com",
              "wrapbook.com",
              "mensabrands.com",
              "heyday.hootsuite.com",
              "place.com",
              "stytch.com",
              "owkin.com",
              "expel.com",
              "nobroker.in",
              "lessen.com",
              "clara.com",
              "yipitdata.com",
              "anyscale.com",
              "iodinesoftware.com",
              "reliaquest.com",
              "petcircle.com.au",
              "naturesfynd.com",
              "lydia-app.com",
              "sellerx.com",
              "snaplogic.com",
              "cadence.care",
              "nonamesecurity.com",
              "rothys.com",
              "voiscooters.com",
              "haomo.ai",
              "kopikenangan.com",
              "infarm.com",
              "groupone-holdings.com",
              "fractal.ai",
              "assent.com",
              "placer.ai",
              "pentera.io",
              "darwinbox.com",
              "min.io",
              "esusurent.com",
              "betterfly.com",
              "dune.com",
              "scandit.com",
              "payhawk.com",
              "wearewatershed.com",
              "axelar.network",
              "cheq.ai",
              "hasura.io",
              "timescale.com",
              "scalapay.com",
              "omadahealth.com",
              "bluevoyant.com",
              "veev.com",
              "vendr.com",
              "gauntlet.network",
              "aptoslabs.com",
              "glia.com",
              "commerceiq.ai",
              "rapidapi.com",
              "taranawireless.com",
              "flashparking.com",
              "electric.ai",
              "bostongene.com",
              "grover.com",
              "genies.com",
              "oysterhr.com",
              "nexhealth.com",
              "upsidefoods.com",
              "factorialhr.com",
              "divergent3d.com",
              "taxfix.de",
              "openvc.com",
              "polarium.com",
              "opn.ooo",
              "remarkable.com",
              "habi.co",
              "glean.com",
              "carebridgeinc.com",
              "immuta.com",
              "jupiterone.com",
              "vayyar.com",
              "leadsquared.com",
              "fourkites.com",
              "vulcanforms.com",
              "singlestore.com",
              "unstoppabledomains.com",
            ],
          ],
        },
        searchId: 2121,
      },
      {
        label: "Founders ex-Tech Corporates 🏢",
        query: {
          SignalDate: SIGNAL_DATE_OPTIONS.THIS_WEEK,
          SignalType: ["New Company"],
          PastCompanyWebsite: [
            "equals",
            [
              "apple.com",
              "beatsbydre.com",
              "microsoft.com",
              "linkedin.com",
              "abc.xyz",
              "youtube.com",
              "google.com",
              "amazon.com",
              "aws.com",
              "nvidia.com",
              "tesla.com",
              "meta.com",
              "facebook.com",
              "instagram.com",
              "whatsapp.com",
              "tsmc.com",
              "tencent.com",
              "broadcom.com",
              "samsung.com",
              "oracle.com",
              "asml.com",
              "alibaba.com",
              "adobe.com",
              "cisco.com",
              "salesforce.com",
              "netflix.com",
              "amd.com",
              "sap.com",
              "ti.com",
              "intel.com",
              "intuit.com",
              "ibm.com",
              "qualcomm.com",
              "appliedmaterials.com",
              "pinduoduo.com",
              "servicenow.com",
              "meituan.com",
              "sony.com",
              "keyence.com",
              "booking.com",
              "adp.com",
              "se.com",
              "uber.com",
              "analog.com",
              "lamresearch.com",
              "airbnb.com",
              "shopify.com",
              "fiserv.com",
              "micron.com",
              "paypal.com",
              "activisionblizzard.com",
              "paloaltonetworks.com",
              "netease.com",
              "equinix.com",
              "tel.com",
              "vmware.com",
              "kla.com",
              "skhynix.com",
              "synopsys.com",
              "mercadolibre.com",
              "cadence.com",
              "jd.com",
              "workday.com",
              "fortinet.com",
              "3ds.com",
              "nxp.com",
              "arista.com",
              "adyen.com",
              "marvell.com",
              "snowflake.com",
              "baidu.com",
              "nintendo.com",
              "ropertech.com",
              "infineon.com",
              "microchip.com",
              "foxconn.com",
              "st.com",
              "onsemi.com",
              "te.com",
              "autodesk.com",
              "block.xyz",
              "atlassian.com",
              "csisoftware.com",
              "thetradedesk.com",
              "deltathailand.com",
              "iqvia.com",
              "palantir.com",
              "dell.com",
              "mi.com",
              "kuaishou.com",
              "crowdstrike.com",
              "murata.com",
              "mediatek.com",
              "sea.com",
              "doordash.com",
              "fisglobal.com",
              "datadoghq.com",
              "ea.com",
              "costargroup.com",
              "symbotic.com",
              "renesas.com",
              "globalfoundries.com",
              "hp.com",
              "coupang.com",
              "amadeus.com",
              "globalpaymentsinc.com",
              "veeva.com",
              "wolterskluwer.com",
            ],
          ],
        },
        searchId: 2122,
      },
      {
        label: "New YC Founders + Applicants 🍊",
        query: {
          Tags: ["contains", ["(A) YC", "YC S", "YC W"]],
        },
        searchId: 1638,
      },
    ],
    "Layoff Tracker 💼": [
      {
        label: "Global Layoffs",
        query: {
          SignalType: ["Searching"],
        },
      },
    ],
  },
  [SpecterProducts.stratintel]: {
    "Interest by Stage 🌳": [
      {
        label: "New Startups 🐥",
        query: {
          growthStage: ["pre_launch", "bootstrapped"],
        },
        searchId: 2165,
      },
      {
        label: "Early Stage 🪴",
        query: {
          growthStage: ["early"],
        },
        searchId: 2170,
      },
      {
        label: "Growth Stage 🌟",
        query: {
          growthStage: ["growing", "late"],
        },
        searchId: 2167,
      },
    ],
    "Interest by Source 🌟": [
      {
        label: "Interest from Top VCs 🏆",
        query: {
          HQRegion: ["Europe"],
          SignalDate: SIGNAL_DATE_OPTIONS.THIS_WEEK,
          growthStage: ["pre_launch", "bootstrapped", "early"],
          SignalSource: [
            "OR",
            [
              "inv_7fb1cbfa5cf171152320f2b4", // "New Enterprise Associates",
              "inv_9eb8496a579270b753955764", // "Y Combinator",
              "inv_5ed808adef8f158e9f38a98f", // "Intel Capital",
              "inv_84f563826c689ceb42aff52a", // "Sequoia Capital",
              "inv_68b0898a81f43beb970f8181", // "Accel",
              "inv_eec6dc9d78bc0ff28903f317", // "Kleiner Perkins",
              "inv_609dba55408c4490fe58cf8e", // "Bessemer Venture Partners",
              "inv_effc15c24648694f7e295fd7", // "Venrock",
              "inv_dfadc422e4dba47eb76bae49", // "Greylock",
              "inv_7d1f3d806987deaf05c04e89", // "Index Ventures",
              "inv_9e4763d7bb3bc7cfaade3667", // "Google Ventures",
              "inv_27ea6064e83463b91030970d", // "Lightspeed Venture Partners
              "inv_1d3c986f2d463149711b1e65", // "Battery Ventures",
              "inv_d9edb518713d429fa16bc3e3", // "Andreessen Horowitz",
              "inv_5c0fbb8db8fde4516cd25e4e", // "Insight Partners",
              "inv_fcb19d09d220c93dc8ea09ff", // "First Round Capital",
              "inv_94c1a1ccb97fe5234bd87e82", // "Benchmark",
              "inv_29af123b59ccf3aa3cfc5cb4", // "General Catalyst",
              "inv_4703839e7313a4bb5d2be789", // "Menlo Ventures",
              "inv_c6b1f7f870b49880eb11970d", // "CRV",
              "inv_8fb74dd3dfc7905fdb35dbfb", // "Felicis",
              "inv_d93f9201182de04afadd94a0", // "Khosla Ventures",
              "inv_028a895cdd9214c56b25165c", // "Canaan Partners",
              "inv_af408539449777927acff7f5", // "Lerer Hippeau",
              "inv_d6d4e2597ed84408a3a4d87e", // "Bossanova Investimentos",
              "inv_5ddbc79acd9907e9c3f3c327", // "Founders Fund",
              "inv_11731f4485e7dbf7d63882fe", // "Tiger Global Management",
              "inv_2d9d1b454804c544ac07f1c5", // "Greycroft",
              "inv_c582a5e377debb54b61b7237", // "Redpoint",
              "inv_9e52868d444d1992b963209f", // "SV Angel",
            ],
          ],
        },
        searchId: 1548,
      },
      {
        label: "Sequoia Tracker",
        query: {
          SignalSource: ["OR", ["inv_84f563826c689ceb42aff52a"]], // Sequoia Capital
        },
        searchId: 2847,
      },
    ],
    "Hot Signals 🔥": [
      {
        label: "Hot Signals (90 days) 🔥",
        query: {
          HotSignals: "90 days",
        },
      },
      {
        label: "Hot Signals (180 days) 🔥",
        query: {
          HotSignals: "180 days",
        },
      },
      {
        label: "Hot Signals (360 days) 🔥",
        query: {
          HotSignals: "360 days",
        },
      },
      {
        label: "Hot Signals (720 days) 🔥",
        query: {
          HotSignals: "720 days",
        },
      },
    ],
  },
  [SpecterProducts.investors]: {
    "Highlights ✨": Object.keys(INVESTOR_HIGHLIGHTS_PROPS).map((option) => {
      const { colorScheme, label } =
        INVESTOR_HIGHLIGHTS_PROPS[option as InvestorHighlight]

      return {
        label: (
          <>
            <Box
              boxSize={2}
              bgColor={`${colorScheme}.400`}
              borderRadius="full"
              mr={2}
            />
            {label}
          </>
        ),
        query: {
          Highlights: ["OR", [option]],
        },
      }
    }),
  },
  [SpecterProducts.fundingRounds]: {
    "Latest Rounds 🚀": {
      query: {
        announcedOn: SIGNAL_DATE_OPTIONS.PAST_30_DAYS,
      },
      sort: {
        raisedAmount: "desc",
      },
    },
  },
  [SpecterProducts.acquisition]: {
    "Billion Dollar Acquisitions 💰": {
      query: {
        acquisitionPrice: ["gte", 1_000_000_000],
      },
    },
  },
  [SpecterProducts.ipo]: {
    "Recent IPOs 🚀": {
      query: {
        wentPublicOn: SIGNAL_DATE_OPTIONS.PAST_30_DAYS,
      },
    },
  },
}
