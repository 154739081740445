import { z } from "zod"

import {
  ANNUAL_REVENUE_RANGES,
  COMPANY_HIGHLIGHTS_OPTIONS,
  COMPANY_SIZE_OPTIONS,
  FUNDING_TYPE,
  GROWTH_STAGE_OPTIONS,
  INDUSTRY_OPTIONS,
  SIGNAL_REGION_OPTIONS,
  SUB_INDUSTRY_OPTIONS,
} from "~/consts/signals"
import {
  booleanFilterSchema,
  dateFilterSchema,
  descriptionFilterSchema,
  numericFilterSchema,
  NUMERIC_OPERATORS,
  OperatingStatusEnum,
  setFilterSchema,
} from "~/utils/signal"
import { signalDateFilterSchema } from "~/utils/signalDate"

const companyCompanyFiltersSchemaObject = {
  Rank: numericFilterSchema({ defaultValue: NUMERIC_OPERATORS.LTE }),
  OperatingStatus: z.array(z.nativeEnum(OperatingStatusEnum)),
  Highlights: setFilterSchema(z.enum(COMPANY_HIGHLIGHTS_OPTIONS)),
  FoundedDate: numericFilterSchema({
    defaultValue: NUMERIC_OPERATORS.GTE,
    extraConstraints: (z) => z.lte(new Date().getFullYear()),
  }),
} as const

export const companyCompanyFiltersSchema = z
  .object(companyCompanyFiltersSchemaObject)
  .partial()

interface CompanyFiltersSchemaOverrides {
  Industry?: Record<string, string>[]
}

export function makeCompanyCompanyFiltersSchema(
  overrides: CompanyFiltersSchemaOverrides
) {
  // take a copy to mutate
  const schemaObject: any = { ...companyCompanyFiltersSchemaObject }

  Object.keys(overrides).forEach((key) => {
    schemaObject[key] = z.array(
      // @ts-ignore -- nativeEnum accepts an object
      z.nativeEnum(overrides[key as keyof CompanyFiltersSchemaOverrides])
    )
  })

  return z.object(schemaObject).partial()
}

export const companyLocationFiltersSchema = z
  .object({
    HQLocation: z.array(z.string()),
    HQRegion: z.array(z.enum(SIGNAL_REGION_OPTIONS)),
  })
  .partial()

export const companyEmployeesFiltersSchema = z
  .object({
    EmployeeCount: numericFilterSchema(),
    EmployeesMonthlyGrowth: numericFilterSchema({ allowNegatives: true }),
    Employees3MonthsGrowth: numericFilterSchema({ allowNegatives: true }),
    Employees6MonthsGrowth: numericFilterSchema({ allowNegatives: true }),
  })
  .partial()

export const companyCompanySizeFiltersSchema = z
  .object({
    CompanySize: z.array(z.nativeEnum(COMPANY_SIZE_OPTIONS)),
  })
  .partial()

export const companyNameSearch = z
  .object({
    nameSearch: z.string().nullable(),
  })
  .partial()

export const companyFundingFiltersSchema = z
  .object({
    TotalFundingAmount: numericFilterSchema({
      defaultValue: NUMERIC_OPERATORS.LTE,
    }),
    LastFundingType: z.array(z.nativeEnum(FUNDING_TYPE)),
    LastFundingAmount: numericFilterSchema(),
    LastFundingDate: dateFilterSchema,
    NumberOfFundingRounds: numericFilterSchema(),
    PostMoneyValuation: numericFilterSchema(),
  })
  .partial()

export const companyInvestorsFiltersSchema = z
  .object({
    Investors: setFilterSchema(),
    NumberOfInvestors: numericFilterSchema(),
  })
  .partial()

export const companyValuationFiltersSchema = z
  .object({
    AnnualRevenueEstimate: z.array(z.nativeEnum(ANNUAL_REVENUE_RANGES)),
  })
  .partial()

export const companyGrowthStageFiltersSchema = z
  .object({
    growthStage: z.array(z.enum(GROWTH_STAGE_OPTIONS)),
  })
  .partial()

export const companyIndustryFiltersSchema = z
  .object({
    Industry: setFilterSchema(z.enum(INDUSTRY_OPTIONS)),
    SubIndustry: setFilterSchema(z.enum(SUB_INDUSTRY_OPTIONS)),
    Description: descriptionFilterSchema,
  })
  .partial()

export const companyWebVisitsFiltersSchema = z
  .object({
    WebVisits: numericFilterSchema(),
    WebVisitsMonthlyGrowth: numericFilterSchema({ allowNegatives: true }),
    WebVisits3MonthsGrowth: numericFilterSchema({ allowNegatives: true }),
    WebVisits6MonthsGrowth: numericFilterSchema({ allowNegatives: true }),
  })
  .partial()

export const companyLinkedInFiltersSchema = z
  .object({
    LinkedInFollowers: numericFilterSchema(),
    LinkedInMonthlyFollowersGrowth: numericFilterSchema({
      allowNegatives: true,
    }),
    LinkedIn3MonthsFollowersGrowth: numericFilterSchema({
      allowNegatives: true,
    }),
    LinkedIn6MonthsFollowersGrowth: numericFilterSchema({
      allowNegatives: true,
    }),
  })
  .partial()

export const companyTwitterFiltersSchema = z
  .object({
    TwitterFollowers: numericFilterSchema(),
    TwitterMonthlyFollowersGrowth: numericFilterSchema({
      allowNegatives: true,
    }),
    Twitter3MonthsFollowersGrowth: numericFilterSchema({
      allowNegatives: true,
    }),
    Twitter6MonthsFollowersGrowth: numericFilterSchema({
      allowNegatives: true,
    }),
  })
  .partial()

export const companyInstagramFiltersSchema = z
  .object({
    InstagramFollowers: numericFilterSchema(),
    InstagramMonthlyFollowersGrowth: numericFilterSchema({
      allowNegatives: true,
    }),
    Instagram3MonthsFollowersGrowth: numericFilterSchema({
      allowNegatives: true,
    }),
    Instagram6MonthsFollowersGrowth: numericFilterSchema({
      allowNegatives: true,
    }),
  })
  .partial()

export const companyAppDownloadsFiltersSchema = z
  .object({
    TotalAppDownloads: numericFilterSchema(),
    TotalAppDownloadsMonthlyGrowth: numericFilterSchema({
      allowNegatives: true,
    }),
    TotalAppDownloads3MonthsGrowth: numericFilterSchema({
      allowNegatives: true,
    }),
    TotalAppDownloads6MonthsGrowth: numericFilterSchema({
      allowNegatives: true,
    }),
  })
  .partial()

export const companyiTunesFiltersSchema = z
  .object({
    iTunesReviews: numericFilterSchema(),
    iTunesMonthlyReviewsGrowth: numericFilterSchema({ allowNegatives: true }),
    iTunes3MonthsReviewsGrowth: numericFilterSchema({ allowNegatives: true }),
    iTunes6MonthsReviewsGrowth: numericFilterSchema({ allowNegatives: true }),
  })
  .partial()

export const companyGoogleFiltersSchema = z
  .object({
    GooglePlayReviews: numericFilterSchema(),
    GooglePlayMonthlyReviewsGrowth: numericFilterSchema({
      allowNegatives: true,
    }),
    GooglePlay3MonthsReviewsGrowth: numericFilterSchema({
      allowNegatives: true,
    }),
    GooglePlay6MonthsReviewsGrowth: numericFilterSchema({
      allowNegatives: true,
    }),
  })
  .partial()

export const companyActiveTechnologiesFiltersSchema = z
  .object({
    ActiveTechnologies: setFilterSchema(),
  })
  .partial()

export const companyAwardsFiltersSchema = z
  .object({
    Awards: setFilterSchema(),
    AwardsCount: numericFilterSchema(),
  })
  .partial()

export const companyG2FiltersSchema = z
  .object({
    G2ProductReviews: booleanFilterSchema,
    G2Medals: booleanFilterSchema,
    G2TotalReviews: numericFilterSchema(),
    G2Rating: numericFilterSchema(),
    // TODO: M and D don't want this for now, add when we have history on products
    // G2Products: textFilterSchema,
  })
  .partial()

export const companyTrustpilotFiltersSchema = z
  .object({
    TrustpilotReviews: booleanFilterSchema,
    TrustpilotTotalReviews: numericFilterSchema(),
    TrustpilotRating: numericFilterSchema(),
  })
  .partial()

const subSection = companyAppDownloadsFiltersSchema.merge(
  companyActiveTechnologiesFiltersSchema
)

const companyNonFilterableFieldsSchema = z
  .object({
    new: z.boolean().optional(),
    NewHighlights: setFilterSchema(z.enum(COMPANY_HIGHLIGHTS_OPTIONS)),
    HasNewHighlights: z.boolean().optional(),
    HasNewFundingHighlights: z.boolean().optional(),
    HasNewGrowthHighlights: z.boolean().optional(),
  })
  .partial()

export const companySocialFiltersSchema = companyLinkedInFiltersSchema
  .merge(companyTwitterFiltersSchema)
  .merge(companyInstagramFiltersSchema)
  .merge(companyiTunesFiltersSchema)
  .merge(companyGoogleFiltersSchema)
  .merge(subSection)

export const companyCRMFiltersSchema = z
  .object({
    LastActivityDate: signalDateFilterSchema.optional(),
    inCRM: z.boolean().optional(),
  })
  .partial()

const DEPRECATED_FIELDS = z
  .object({
    // * Add deprecated fields here:
    Tags: z.literal("DEPRECATED"),
  })
  .partial()

const extra = companyNameSearch
  .merge(companyNonFilterableFieldsSchema)
  .merge(companyAwardsFiltersSchema)
  .merge(companyG2FiltersSchema)
  .merge(companyTrustpilotFiltersSchema)
  .merge(companySocialFiltersSchema)
  .merge(companyCRMFiltersSchema)
  .merge(DEPRECATED_FIELDS)

export const companySignalFiltersValidation = companyCompanyFiltersSchema
  .merge(companyIndustryFiltersSchema)
  .merge(companyGrowthStageFiltersSchema)
  .merge(companyLocationFiltersSchema)
  .merge(companyFundingFiltersSchema)
  .merge(companyInvestorsFiltersSchema)
  .merge(companyValuationFiltersSchema)
  .merge(companyEmployeesFiltersSchema)
  .merge(companyCompanySizeFiltersSchema)
  .merge(companyWebVisitsFiltersSchema)
  .merge(extra)

export type CompanySignalFilters = z.infer<
  typeof companySignalFiltersValidation
>

export type DateOperator = "after" | "equals" | "before"

export const getDateOperator = (operator: DateOperator) => {
  return {
    after: "gt",
    before: "lt",
    equals: "equals",
  }[operator]
}

export const companyAdvancedFiltersSchema = z.object({
  Overview: z.object({
    Overview: companyCompanyFiltersSchema,
  }),
  Location: z.object({
    Location: companyLocationFiltersSchema,
  }),
  "Growth Stage": z.object({
    growthStage: companyGrowthStageFiltersSchema,
  }),
  Industry: z.object({
    Industry: companyIndustryFiltersSchema,
  }),
  Financials: z.object({
    Funding: companyFundingFiltersSchema,
    Investors: companyInvestorsFiltersSchema,
    Financials: companyValuationFiltersSchema,
  }),
  Team: z.object({
    Employees: companyEmployeesFiltersSchema,
    "Company Size": companyCompanySizeFiltersSchema,
  }),
  "Website Intelligence": z.object({
    WebVisits: companyWebVisitsFiltersSchema,
  }),
  "Social Engagement": z.object({
    LinkedIn: companyLinkedInFiltersSchema,
    Twitter: companyTwitterFiltersSchema,
    Instagram: companyInstagramFiltersSchema,
  }),
  "App Performance": z.object({
    TotalAppDownloads: companyAppDownloadsFiltersSchema,
    iTunes: companyiTunesFiltersSchema,
    GooglePlay: companyGoogleFiltersSchema,
  }),
  Product: z.object({
    "G2 Data": companyG2FiltersSchema,
    "Trustpilot Data": companyTrustpilotFiltersSchema,
    ActiveTechnologies: companyActiveTechnologiesFiltersSchema,
  }),
  Awards: z.object({
    Awards: companyAwardsFiltersSchema,
  }),
  "My CRM": z.object({
    "My CRM": companyCRMFiltersSchema,
  }),
})

export const companySignalQuickFiltersSchema = z
  .object({
    Rank: numericFilterSchema({
      defaultValue: NUMERIC_OPERATORS.LTE,
    }),
    Highlights: setFilterSchema(z.enum(COMPANY_HIGHLIGHTS_OPTIONS)),
    HQLocation: z.array(z.string()),
    growthStage: z.array(z.enum(GROWTH_STAGE_OPTIONS)),
    Industry: setFilterSchema(z.enum(INDUSTRY_OPTIONS)),
    SubIndustry: setFilterSchema(z.enum(SUB_INDUSTRY_OPTIONS)),
  })
  .partial()

export type CompanySignalQuickFilters = z.infer<
  typeof companySignalQuickFiltersSchema
>
